import { Typography } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import SearchInput from '../components/inputs/search-input';
import Filters from '../components/lists/filters';
import GridSizer from '../components/lists/grid-sizer';
import ProductList, { ProductListProps } from '../components/lists/product/product-list';
import Panel from '../components/panels/v2/panel';
import PanelContent from '../components/panels/v2/panel-content';
import PanelFooter from '../components/panels/v2/panel-footer';
import PanelHeader from '../components/panels/v2/panel-header';
import Header from '../components/typography/header';
import { FieldFilterDefinition } from '../generated/gql/graphql';
import useProductFilters from '../hooks/use-product-filters';

export type ProductsPanelProps = Pick<ProductListProps, 'selectedProductId' | 'productSelected'> & {
    editorNumberItems: number;
};

export default function ProductsPanel(props: ProductsPanelProps): ReactElement {
    const { selectedProductId, editorNumberItems, productSelected } = props;
    const { options: availableFilters } = useProductFilters();

    const [activeFilters, setActiveFilters] = useState<FieldFilterDefinition[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [gridSize, setGridSize] = useState<number>(100);

    const isEditorObjectsMaxLimit = editorNumberItems && editorNumberItems >= 10;

    return (
        <section className='h-1/2'>
            <Panel>
                <PanelHeader classNames='justify-between space-x-5'>
                    <Header>Products</Header>
                    <SearchInput value={searchQuery} onChange={setSearchQuery} />
                </PanelHeader>
                <PanelContent>
                    {isEditorObjectsMaxLimit ? (
                        <>
                            <Typography className='p-5 text-center font-bold uppercase text-companyMediumGrey'>
                                Maximum number of products reached
                            </Typography>
                            <Typography className='text-center font-semibold text-companyMediumGrey'>
                                You have already added {editorNumberItems}/10 products. Please delete a product from the
                                scene to add a new one.
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Filters
                                filters={availableFilters}
                                selectedFilters={activeFilters}
                                onChangeFilters={setActiveFilters}
                            />
                            <ProductList
                                query={searchQuery}
                                filters={activeFilters}
                                gridSize={gridSize}
                                selectedProductId={selectedProductId}
                                productSelected={productSelected}
                            />
                        </>
                    )}
                </PanelContent>
                <PanelFooter>
                    <GridSizer
                        gridSize={gridSize}
                        setGridSize={setGridSize}
                        step={10}
                        minGridSize={50}
                        maxGridSize={150}
                    />
                </PanelFooter>
            </Panel>
        </section>
    );
}
