import { Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';

import { Background } from '../../../generated/gql/graphql';
import { classNames } from '../../../utils/classes';

type Props = Background & {
    selected: boolean;
    gridSize: number;
    onClick: (id: string) => void;
    displayType: 'full' | 'auto';
};

export default function BackgroundListItem(props: Props): ReactElement {
    const { id, name, thumbnailUrl, onClick, displayType, selected, gridSize } = props;

    return (
        <div style={{ width: `${gridSize}px` }} key={id} className='inline-block cursor-pointer break-all'>
            <button type='button' className='flex max-w-full flex-col space-y-2' onClick={() => onClick(id)}>
                <img
                    src={thumbnailUrl}
                    alt={`${name} thumbnail`}
                    style={{
                        width: displayType ? '100%' : 'auto',
                        aspectRatio: '1/1',
                    }}
                    className={classNames(
                        'rounded object-contain',
                        selected ? 'ring-4 ring-company ring-offset-4' : '',
                    )}
                />
                <Tooltip title={name} placement='bottom' arrow>
                    <p className='max-w-full truncate text-sm text-gray-500'>{name}</p>
                </Tooltip>
            </button>
        </div>
    );
}
