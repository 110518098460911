import Cookies from "js-cookie";
import { useCallback, useState } from "react";

export default function useCookie(
    name: string, 
    defaultValue: string
): any {
    const [value, setValue] = useState<string | null>(() => {
        const cookie = Cookies.get(name);

        if (cookie) return cookie;

        Cookies.set(name, defaultValue);

        return defaultValue;
    });

    const updateCookie = useCallback(
        (newValue: string) => {
            Cookies.set(name, newValue);
            setValue(newValue);
        },
        [name]
    )

    const deleteCookie = useCallback(() => {
        Cookies.remove(name);
        setValue(null);
    }, [name])

    return [value, updateCookie, deleteCookie];
}