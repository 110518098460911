import { QueryResult, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql/gql';
import { GetProductPresentationsQuery } from '../generated/gql/graphql';

import { ArrayElement } from './utils';

export type Product = NonNullable<GetProductPresentationsQuery['product']>;
export type ProductPerspective = ArrayElement<Product['perspectives']>;
export type ProductAnimation = ArrayElement<Product['animations']>;

const GET_PRODUCT_PRESENTATIONS = graphql(`
    query GetProductPresentations($productId: ID!) {
        product(id: $productId) {
            id
            name
            perspectives {
                id
                type
                name
                thumbnailUrl
                url
            }
            animations {
                id
                type
                name
                thumbnailUrl
                url
            }
        }
    }
`);

export default function useProductPresentations(productId: string): QueryResult<GetProductPresentationsQuery> {
    return useQuery<GetProductPresentationsQuery>(GET_PRODUCT_PRESENTATIONS, {
        variables: {
            productId,
        },
    });
}
