import React, { ReactElement } from 'react';

import { FieldFilterDefinition, FieldFilterOption } from '../../generated/gql/graphql';
import useLocale from '../../hooks/use-locale';
import CloseIcon from '../icons/fill/close';
import MultiDropdownSelector from '../selectors/multi-dropdown-selector';

type Props = {
    filters: FieldFilterDefinition[];
    selectedFilters: FieldFilterDefinition[];
    onChangeFilters(filters: FieldFilterDefinition[]): void;
};

export default function Filters(props: Props): ReactElement {
    const { filters, onChangeFilters, selectedFilters } = props;

    const { getText } = useLocale();

    function clearFilters(): void {
        onChangeFilters([]);
    }

    function removeFilterOption(
        filter: FieldFilterDefinition,
        target: FieldFilterDefinition,
        option: FieldFilterOption,
    ): void {
        const newFilterOptions = target.options.filter((o) => o.value !== option.value);
        const otherFilters = selectedFilters.filter((f) => f.fieldName !== filter.fieldName);
        if (newFilterOptions.length > 0) {
            onChangeFilters([
                ...otherFilters,
                {
                    ...target,
                    options: newFilterOptions,
                },
            ]);
        } else {
            onChangeFilters(otherFilters);
        }
    }

    function addFilterOption(
        filter: FieldFilterDefinition,
        target: FieldFilterDefinition,
        option: FieldFilterOption,
    ): void {
        onChangeFilters([
            ...selectedFilters.filter((f) => f.fieldName !== filter.fieldName),
            {
                ...target,
                options: [...target.options, option],
            },
        ]);
    }

    function handleSelectFilter(filter: FieldFilterDefinition, option: FieldFilterOption): void {
        const target = selectedFilters.find((f) => f.fieldName === filter.fieldName);
        if (!target) {
            onChangeFilters([...selectedFilters, { ...filter, options: [option] }]);
            return;
        }

        const targetOption = target.options.find((o) => o.value === option.value);
        if (targetOption) {
            removeFilterOption(filter, target, option);
        } else {
            addFilterOption(filter, target, option);
        }
    }

    return (
        <div className='mt-3 flex w-full max-w-full flex-col space-y-2'>
            <div className='w-fit'>
                {filters.map((filter) => (
                    <MultiDropdownSelector
                        key={filter.name}
                        categoryName={filter.name}
                        selections={selectedFilters?.map((v) => v.options).flat()}
                        options={filter.options}
                        onSelect={(v) => handleSelectFilter(filter, v)}
                    />
                ))}
            </div>
            <div className='flex w-full items-center justify-between'>
                <ul className='flex min-h-[24px] flex-wrap gap-2 overflow-x-auto'>
                    {selectedFilters.map((filter) =>
                        filter.options.map((option) => (
                            <li
                                key={option.value}
                                className='flex h-6 w-fit items-center space-x-2 rounded-full bg-companyLightGrey p-2'
                            >
                                <span className='whitespace-nowrap text-sm text-companyDarkGrey'>{option.label}</span>
                                <button type='button' onClick={() => handleSelectFilter(filter, option)}>
                                    <CloseIcon className='fill-companyMediumGrey opacity-50' width={15} height={15} />
                                </button>
                            </li>
                        )),
                    )}
                    {selectedFilters.length > 0 && (
                        <li>
                            <button className='mb-auto w-fit' type='button' onClick={() => clearFilters()}>
                                <span className='whitespace-nowrap text-sm text-companyMediumGrey'>
                                    {getText('components.filter.clear')}
                                </span>
                            </button>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
}
