import React, { ReactElement } from 'react';

import GridIcon from '../icons/fill/grid';
import GridMoreIcon from '../icons/fill/grid-more';

type Props = {
    gridSize: number;
    setGridSize(size: number): void;
    maxGridSize?: number;
    minGridSize?: number;
    step?: number;
};

export default function GridSizer(props: Props): ReactElement {
    const { gridSize, setGridSize, minGridSize, maxGridSize, step } = props;

    return (
        <div className='mt-3 flex justify-end space-x-2 px-5 pb-5'>
            <GridMoreIcon width={18} height={18} className='fill-companyMediumGrey' />
            <input
                // style={{ direction: 'rtl' }}
                type='range'
                min={minGridSize}
                max={maxGridSize}
                step={step}
                className='w-1/6'
                value={gridSize}
                onChange={(e) => {
                    setGridSize(Number.parseInt(e.target.value, 10));
                }}
            />
            <GridIcon width={18} height={18} className='fill-companyMediumGrey' />
        </div>
    );
}

GridSizer.defaultProps = {
    minGridSize: 5,
    maxGridSize: 9,
    step: 1,
};
