import './styles/globals.css';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './app';
import { AppConfigProvider } from './providers/config/app-config-provider';
import GeneratorProvider from './providers/generator-provider';
import GraphqlProvider from './providers/graphql-provider';
import KeycloakProvider from './providers/keycloak-provider';
import MaintenanceProvider from './providers/maintenance-provider';
import MarkerIoProvider from './providers/marker-io-provider';
import NotificationProvider from './providers/notification-provider';
import ProjectSceneProvider from './providers/project-scene-provider';

const root = createRoot(document.querySelector('#root') as HTMLElement);
root.render(
    <MarkerIoProvider>
        <NotificationProvider>
            <AppConfigProvider>
                <KeycloakProvider>
                    <GraphqlProvider>
                        <MaintenanceProvider>
                            <ProjectSceneProvider>
                                <GeneratorProvider>
                                    <StyledEngineProvider injectFirst>
                                        <CssBaseline />
                                        <App />
                                    </StyledEngineProvider>
                                </GeneratorProvider>
                            </ProjectSceneProvider>
                        </MaintenanceProvider>
                    </GraphqlProvider>
                </KeycloakProvider>
            </AppConfigProvider>
        </NotificationProvider>
    </MarkerIoProvider>,
);
