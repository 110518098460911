import { FileUploadOutlined } from '@mui/icons-material';
import React, { ReactElement, useRef, useState } from 'react';

import IconButton from '../components/button/icon-button';
import SearchInput from '../components/inputs/search-input';
import { BackgroundList, BackgroundListProps, BackgroundListRef } from '../components/lists/background/background-list';
import Filters from '../components/lists/filters';
import GridSizer from '../components/lists/grid-sizer';
import Panel from '../components/panels/v2/panel';
import PanelContent from '../components/panels/v2/panel-content';
import PanelFooter from '../components/panels/v2/panel-footer';
import PanelHeader from '../components/panels/v2/panel-header';
import Header from '../components/typography/header';
import { FieldFilterDefinition } from '../generated/gql/graphql';
import useBackgroundFilters from '../hooks/use-background-filters';
import useLocale from '../hooks/use-locale';
import AssetUploadModal from '../modals/asset-upload-modal';

export type BackgroundsPanelProps = Pick<BackgroundListProps, 'selectedBackgroundId' | 'selectedBackgroundIdChange'>;

export default function BackgroundsPanel({
    selectedBackgroundId,
    selectedBackgroundIdChange,
}: BackgroundsPanelProps): ReactElement {
    const { fieldFilters: availableFilters } = useBackgroundFilters();
    const { getText } = useLocale();

    const [isUploadOpen, setIsUploadOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [activeFilters, setActiveFilters] = useState<FieldFilterDefinition[]>([]);
    const [gridSize, setGridSize] = useState<number>(100);

    const backgroundRef = useRef<BackgroundListRef>(null);

    function handleCloseModal(): void {
        setIsUploadOpen(false);
        backgroundRef.current?.refetch();
    }

    return (
        <section className='h-1/2'>
            <Panel>
                <PanelHeader classNames='justify-between space-x-5'>
                    <Header>{getText('sections.backgroundsPanel.headline')}</Header>
                    <div className='flex space-x-5'>
                        <SearchInput value={searchQuery} onChange={setSearchQuery} />
                        <IconButton
                            icon={<FileUploadOutlined className='!fill-companyMediumGrey' />}
                            onClick={() => setIsUploadOpen(true)}
                        />
                    </div>
                </PanelHeader>
                <PanelContent>
                    <Filters
                        filters={availableFilters}
                        onChangeFilters={setActiveFilters}
                        selectedFilters={activeFilters}
                    />
                    <BackgroundList
                        ref={backgroundRef}
                        query={searchQuery}
                        filters={activeFilters}
                        gridSize={gridSize}
                        selectedBackgroundId={selectedBackgroundId}
                        selectedBackgroundIdChange={selectedBackgroundIdChange}
                    />
                </PanelContent>
                <PanelFooter>
                    <GridSizer
                        gridSize={gridSize}
                        setGridSize={setGridSize}
                        step={10}
                        minGridSize={50}
                        maxGridSize={150}
                    />
                </PanelFooter>
            </Panel>
            <AssetUploadModal
                headline={getText('sections.assetUpload.background.headline')}
                open={isUploadOpen}
                onClose={() => handleCloseModal()}
            />
        </section>
    );
}
