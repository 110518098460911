import React, { ReactElement } from 'react';

import { FieldFilterDefinition } from '../../../generated/gql/graphql';
import useProducts, { Product } from '../../../hooks/use-products';
import useWindowSize from '../../../hooks/use-window-size';
import RetryError from '../../errors/retry-error';
import NoResults from '../no-results';
import SkeletonList from '../skeleton-list';

import ProductListItem from './product-list-item';

export type ProductListProps = {
    query: string;
    filters: FieldFilterDefinition[];
    gridSize: number;
    selectedProductId: string | undefined;
    productSelected: (product: Product) => void;
};

export default function ProductList(props: ProductListProps): ReactElement {
    const { query, filters, gridSize, selectedProductId, productSelected } = props;

    const {
        loading,
        error,
        data: productsData,
    } = useProducts({
        searchQuery: query,
        filters,
    });
    const productsListHeight = useWindowSize().height;

    if (loading) {
        return <SkeletonList gridSize={gridSize} />;
    }

    if (error) {
        return <RetryError error={error.message} />;
    }

    const products = productsData?.products;
    if (!products || products.length === 0) {
        return <NoResults />;
    }

    return (
        <div
            style={{
                minHeight: `${productsListHeight / 2 - 300 || 100}px`,
            }}
            className='flex h-48 flex-wrap gap-3 overflow-y-auto px-5 py-3'
        >
            {products.map((product) => (
                <ProductListItem
                    key={product.id}
                    gridSize={gridSize}
                    onClick={() => productSelected(product)}
                    selected={selectedProductId === product.id}
                    displayType={gridSize === 2 ? 'full' : 'auto'}
                    {...product}
                />
            ))}
        </div>
    );
}
