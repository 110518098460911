import React, { ForwardRefExoticComponent, ReactElement, forwardRef, useImperativeHandle } from 'react';

import { FieldFilterDefinition } from '../../../generated/gql/graphql';
import useBackgrounds from '../../../hooks/use-backgrounds';
import useWindowSize from '../../../hooks/use-window-size';
import RetryError from '../../errors/retry-error';
import NoResults from '../no-results';
import SkeletonList from '../skeleton-list';

import BackgroundListItem from './background-list-item';

export type BackgroundListProps = {
    query: string;
    filters: FieldFilterDefinition[];
    gridSize: number;
    selectedBackgroundId: string | undefined;
    selectedBackgroundIdChange: (backgroundId: string | undefined) => void;
};

export interface BackgroundListRef extends ForwardRefExoticComponent<ReactElement> {
    refetch(): void;
}

// eslint-disable-next-line react/display-name
export const BackgroundList = forwardRef<BackgroundListRef, BackgroundListProps>((props, ref): ReactElement => {
    const { query, gridSize, filters, selectedBackgroundId, selectedBackgroundIdChange } = props;

    const { loading, backgrounds, error, refetch } = useBackgrounds({
        searchQuery: query,
        filters,
    });
    const backgroundListHeight = useWindowSize().height;

    useImperativeHandle(
        ref,
        () =>
            ({
                refetch,
            }) as BackgroundListRef,
    );

    const handleItemClick = (id: string): void => {
        selectedBackgroundIdChange(id);
    };

    if (loading) {
        return <SkeletonList gridSize={gridSize} />;
    }

    if (error) {
        return <RetryError error={error} />;
    }

    if (backgrounds.length === 0) {
        return <NoResults />;
    }

    return (
        <div
            style={{
                minHeight: `${backgroundListHeight / 2 - 300}px`,
            }}
            className='flex h-48 flex-wrap gap-3 overflow-y-auto px-5 py-3'
        >
            {backgrounds.map((background) => (
                <BackgroundListItem
                    key={background.id}
                    gridSize={gridSize}
                    onClick={handleItemClick}
                    selected={selectedBackgroundId === background.id}
                    displayType={gridSize === 2 ? 'full' : 'auto'}
                    {...background}
                />
            ))}
        </div>
    );
});
