import React, { ReactElement, createContext, useMemo, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import useCookie from '../hooks/use-cookie';
import ReleaseNotesModal from '../modals/release-notes-modal';

type NotificationContextType = {
    showError(message: string, title?: string): void;
    showSuccess(message: string, title?: string): void;
    showInfo(message: string, title?: string): void;
};

export const NotificationContext = createContext<NotificationContextType>({
    showError: () => {},
    showSuccess: () => {},
    showInfo: () => {},
});

type Props = {
    children: ReactElement;
};

export default function NotificationProvider(props: Props): ReactElement {
    const { children } = props;
    const [value, update] = useCookie('releaseInfo', '');
    const [isReleaseInfo, setIsReleaseInfo] = useState<boolean>(!value);

    function getToast(message: string, title?: string): ReactElement {
        return (
            <div>
                {title && <p className='text-lg font-bold'>{title}</p>}
                <p>{message}</p>
            </div>
        );
    }

    function onShowError(message: string, title?: string): void {
        toast.error(getToast(message, title), {
            progress: undefined,
        });
    }

    function onShowSuccess(message: string, title?: string): void {
        toast.success(getToast(message, title), {
            progress: undefined,
        });
    }

    function onShowInfo(message: string, title?: string): void {
        toast.info(getToast(message, title), {
            progress: undefined,
        });
    }

    const values = useMemo(
        () => ({
            showError: onShowError,
            showSuccess: onShowSuccess,
            showInfo: onShowInfo,
        }),
        [onShowError, onShowSuccess, onShowInfo],
    );

    return (
        <NotificationContext.Provider value={values}>
            {children}
            <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                theme='light'
                draggable={false}
            />
            {isReleaseInfo && (
                <ReleaseNotesModal
                    headline='Metashot App / Update 11.2024'
                    onClose={() => {
                        update('read');
                        setIsReleaseInfo(!isReleaseInfo);
                    }}
                />
            )}
        </NotificationContext.Provider>
    );
}
