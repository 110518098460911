import { useLazyQuery } from '@apollo/client';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { ReactElement, useState } from 'react';

import { graphql } from '../../generated/gql';
import {
    CreateRenderingJobSetting,
    Layout,
    LayoutRenderingProgress,
    LayoutStatus,
    Project,
} from '../../generated/gql/graphql';
import useDeleteLayout from '../../hooks/use-delete-layout';
import useLocale from '../../hooks/use-locale';
import usePreviewLayout from '../../hooks/use-preview-layout';
import useRenderLayout from '../../hooks/use-render-layout';
import useRenderingSettings from '../../hooks/use-rendering-settings';
import useUpdateLayout from '../../hooks/use-update-layout';
import DuplicateLayoutModal from '../../modals/duplicate-layout-modal';
import GeneratorModal from '../../modals/generator-modal';
import LayoutRenameModal from '../../modals/layout-rename-modal';
import { downloadFiles } from '../../utils/download-files';
import { NonPartial } from '../../utils/types';
import ArchivedIcon from '../icons/outlines/archived';
import CopyIcon from '../icons/outlines/copy';
import IconDownload from '../icons/outlines/download';
import GeneratedIcon from '../icons/outlines/generate';
import PreviewIcon from '../icons/outlines/preview';
import TrashIcon from '../icons/outlines/trash';

type Props = {
    layout: NonPartial<Pick<Layout, 'id' | 'name' | 'status' | 'renderingProgress' | 'readOnly'>>;
    project: NonPartial<Pick<Project, 'id' | 'workspaceId' | 'readOnly'> | undefined>;
    layoutsChange: () => void | Promise<void>;
};

const GET_LAYOUT_LATEST_RENDERING_JOB = graphql(`
    query GetLayoutLatestRenderingJob($layoutId: ID!) {
        layout(id: $layoutId) {
            latestRenderingJob {
                id
                result {
                    outputs {
                        name
                        url
                    }
                    renderingUrl
                    outputPackageUrl
                }
            }
        }
    }
`);

const GET_LAYOUT_PREVIEW_RENDERING_JOB = graphql(`
    query GetLayoutPreviewRenderingJob($layoutId: ID!) {
        layout(id: $layoutId) {
            previewRenderingJob {
                result {
                    renderingUrl
                }
            }
        }
    }
`);

export default function LayoutDropdownSelector(props: Props): ReactElement {
    const { layout, project, layoutsChange } = props;

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<undefined | HTMLElement>(undefined);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);

    const { settings } = useRenderingSettings();
    const { getText } = useLocale();

    const { renderPreviewLayoutById } = usePreviewLayout();
    const { deleteLayoutById } = useDeleteLayout();
    const { archiveLayoutById } = useUpdateLayout();
    const { renderLayout } = useRenderLayout();

    const handleToggleRenameModal = (): void => {
        setIsRenameModalOpen(!isRenameModalOpen);
    };

    const handleRenameModalClosed = async (nameChanged: boolean): Promise<void> => {
        if (nameChanged) {
            layoutsChange();
        }
        setIsRenameModalOpen(false);
    };

    const handleDuplicateModalClosed = (): void => {
        setIsDuplicateModalOpen(false);
    };

    const handleDuplicateModalOpen = (): void => {
        setIsDuplicateModalOpen(true);
    };

    const handleDeleteLayout = async (): Promise<void> => {
        alert(getText('notifications.layouts.removeLayout'));
        // eslint-disable-next-line no-restricted-globals, no-alert
        if (confirm('Are you sure you would like to delete this layout?')) {
            await deleteLayoutById(layout.id);
            await layoutsChange();
        }
    };

    const handleArchiveLayout = async (): Promise<void> => {
        await archiveLayoutById(layout.id);
        await layoutsChange();

        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleGenerateLayout = async (): Promise<void> => {
        setIsGenerateModalOpen(true);
        setIsDropdownOpen(!isDropdownOpen);
    };

    const [latestRenderingJobQuery] = useLazyQuery(GET_LAYOUT_LATEST_RENDERING_JOB, { fetchPolicy: 'network-only' });
    const [previewRenderingJobQuery] = useLazyQuery(GET_LAYOUT_PREVIEW_RENDERING_JOB, { fetchPolicy: 'network-only' });
    const handleDownloadLayout = async (): Promise<void> => {
        const layoutId = layout.id;
        let downloadUrl;
        let downloadBasename;
        const layoutName = layout.name ?? 'unknown';
        const filesToDownload = [];
        if (layout.renderingProgress === LayoutRenderingProgress.Generated) {
            // Fetch info about latest rendering job from backend
            const response = await latestRenderingJobQuery({ variables: { layoutId } });
            const result = response.data?.layout.latestRenderingJob?.result;
            if (result) {
                downloadBasename = layoutName;
                if (result.outputPackageUrl) {
                    downloadUrl = response.data?.layout.latestRenderingJob?.result?.outputPackageUrl;
                } else {
                    filesToDownload.push({ url: result.renderingUrl, basename: layoutName });
                    const outputs = result.outputs;
                    if (outputs) {
                        for (const output of outputs) {
                            filesToDownload.push({ url: output.url, basename: output.name });
                        }
                    }
                }
            }
        } else if (layout.renderingProgress === LayoutRenderingProgress.PreviewAvailable) {
            // Fetch info about preview rendering job from backend
            const response = await previewRenderingJobQuery({ variables: { layoutId } });
            downloadUrl = response.data?.layout.previewRenderingJob?.result?.renderingUrl;
            downloadBasename = `${layoutName}_preview`;
        }

        setIsDropdownOpen(!isDropdownOpen);

        if (downloadUrl) {
            const downloadElement = document.createElement('a');
            downloadElement.href = downloadUrl;
            if (downloadBasename) {
                downloadElement.download = downloadBasename;
            }
            document.body.appendChild(downloadElement);
            downloadElement.click();
            downloadElement.remove();
        } else {
            await downloadFiles(filesToDownload, downloadBasename);
        }
    };

    const handleGeneratePreview = async (): Promise<void> => {
        await renderPreviewLayoutById(layout.id);

        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleDropdown = (event: React.MouseEvent<HTMLElement>): void => {
        setIsDropdownOpen(!isDropdownOpen);
        setAnchorEl(event.currentTarget ?? undefined);
    };

    const handleGenerateSubmit = async (
        renderingSettings: CreateRenderingJobSetting[],
        disableShadowsAndReflections: boolean | undefined,
    ): Promise<void> => {
        setIsGenerateModalOpen(false);
        await renderLayout({ layoutId: layout.id, settings: renderingSettings, disableShadowsAndReflections });
    };

    const handleGenerateCancel = (): void => {
        setIsGenerateModalOpen(false);
    };

    // Collect menu items
    const menuItems = [];

    const { renderingProgress, status, readOnly } = layout;

    // Create Preview
    if (renderingProgress !== LayoutRenderingProgress.Generated && status !== LayoutStatus.Archived && !readOnly) {
        const disabled =
            renderingProgress === LayoutRenderingProgress.InProgress ||
            renderingProgress === LayoutRenderingProgress.PreviewInProgress ||
            renderingProgress === LayoutRenderingProgress.PreviewAvailable;
        menuItems.push(
            <MenuItem
                key='preview'
                disabled={disabled}
                onClick={handleGeneratePreview}
                className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
            >
                <Tooltip title='Create Preview' placement='right' arrow>
                    <span>
                        <PreviewIcon />
                    </span>
                </Tooltip>
            </MenuItem>,
        );
    }

    // Download
    if (!readOnly) {
        const downloadDisabled =
            renderingProgress !== LayoutRenderingProgress.Generated &&
            renderingProgress !== LayoutRenderingProgress.PreviewAvailable;
        menuItems.push(
            <MenuItem
                key='download'
                disabled={downloadDisabled}
                onClick={handleDownloadLayout}
                className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
            >
                <Tooltip title='Download' placement='right' arrow>
                    <span>
                        <IconDownload />
                    </span>
                </Tooltip>
            </MenuItem>,
        );
    }

    // Generate
    if (status !== LayoutStatus.Archived && !readOnly) {
        const disabled =
            renderingProgress === LayoutRenderingProgress.InProgress ||
            renderingProgress === LayoutRenderingProgress.PreviewInProgress ||
            renderingProgress === LayoutRenderingProgress.Generated;
        menuItems.push(
            <MenuItem
                key='generate'
                disabled={disabled}
                onClick={handleGenerateLayout}
                className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
            >
                <Tooltip title='Generate' placement='right' arrow>
                    <span>
                        <GeneratedIcon />
                    </span>
                </Tooltip>
            </MenuItem>,
        );
    }

    // Duplicate
    menuItems.push(
        <MenuItem
            key='duplicate'
            onClick={(e) => {
                handleDuplicateModalOpen();
                toggleDropdown(e);
            }}
            className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
        >
            <Tooltip title='Duplicate' placement='right' arrow>
                <span>
                    <CopyIcon />
                </span>
            </Tooltip>
        </MenuItem>,
    );

    // Rename
    if (status !== LayoutStatus.Archived && !readOnly) {
        const disabled =
            renderingProgress === LayoutRenderingProgress.InProgress ||
            renderingProgress === LayoutRenderingProgress.PreviewInProgress;
        menuItems.push(
            <MenuItem
                key='rename'
                disabled={disabled}
                onClick={(e) => {
                    handleToggleRenameModal();
                    toggleDropdown(e);
                }}
                className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
            >
                <Tooltip title='Rename' placement='right' arrow>
                    <span>
                        <ModeEditOutlineOutlinedIcon />
                    </span>
                </Tooltip>
            </MenuItem>,
        );
    }

    // Archive
    if (renderingProgress === LayoutRenderingProgress.Generated && status !== LayoutStatus.Archived && !readOnly) {
        menuItems.push(
            <MenuItem
                key='archive'
                onClick={handleArchiveLayout}
                className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
            >
                <Tooltip title='Archive' placement='right' arrow>
                    <span>
                        <ArchivedIcon />
                    </span>
                </Tooltip>
            </MenuItem>,
        );
    }

    // Delete
    if (status !== LayoutStatus.Archived && !readOnly) {
        const disabled =
            renderingProgress === LayoutRenderingProgress.InProgress ||
            renderingProgress === LayoutRenderingProgress.Generated ||
            renderingProgress === LayoutRenderingProgress.PreviewInProgress;
        menuItems.push(
            <MenuItem
                key='delete'
                disabled={disabled}
                onClick={handleDeleteLayout}
                className='hover:text-[#999999] hover:[&>span>svg]:fill-company'
            >
                <Tooltip title='Delete' placement='right' arrow>
                    <span>
                        <TrashIcon width={24} height={24} />
                    </span>
                </Tooltip>
            </MenuItem>,
        );
    }

    return (
        <div className='absolute !right-[17px] !top-[17px] rounded-[25px] bg-white'>
            <IconButton onClick={toggleDropdown}>
                <MoreVertIcon style={{ color: isDropdownOpen ? '#DD2127' : '' }} />
            </IconButton>
            {isDropdownOpen && (
                <Menu
                    id='long-menu'
                    className='rounded-[15px] shadow'
                    MenuListProps={{ 'aria-labelledby': 'long-button' }}
                    anchorEl={anchorEl}
                    open={isDropdownOpen}
                    onClose={toggleDropdown}
                    PaperProps={{
                        style: {
                            width: 'auto',
                            padding: 0,
                            borderRadius: '15px',
                        },
                    }}
                >
                    {menuItems}
                </Menu>
            )}
            <LayoutRenameModal
                headline='Rename layout'
                layout={layout}
                open={isRenameModalOpen}
                onClose={handleRenameModalClosed}
            />
            <DuplicateLayoutModal
                project={project}
                layout={layout}
                open={isDuplicateModalOpen}
                layoutsChange={layoutsChange}
                onClose={handleDuplicateModalClosed}
            />
            {!!settings?.length && (
                <GeneratorModal
                    open={isGenerateModalOpen}
                    settings={settings}
                    submit={(renderingSettings, disableShadowsAndReflections) =>
                        handleGenerateSubmit(renderingSettings, disableShadowsAndReflections)
                    }
                    cancel={() => handleGenerateCancel()}
                />
            )}
        </div>
    );
}
