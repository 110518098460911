import React, { ReactElement } from 'react';

import NodeTreeIcon from '../icons/fill/node-tree';

export default function NodeTreeBadge({ size }: { size: number }): ReactElement {
    return (
        <div className='absolute bottom-1 right-1 rounded-full bg-company bg-opacity-50 p-1 shadow'>
            <NodeTreeIcon className='fill-white' height={size} width={size} />
        </div>
    );
}
