import { ApolloError, useQuery } from '@apollo/client';

import { graphql } from '../generated/gql';
import { GetLayoutsQuery, LayoutRenderingProgress, LayoutSortField, LayoutStatus } from '../generated/gql/graphql';
import { StringBoolean } from '../types/enums/string-boolean';

import { ArrayElement } from './utils';

type ProjectApi = {
    loading: boolean;
    error?: ApolloError;
    fetchLayouts?: ProjectLayouts;
    refetchLayouts: () => Promise<ProjectLayouts | undefined>;
};

export type FilterLayout = {
    name: string;
    status: string;
    generation: string;
};

export type ProjectLayouts = GetLayoutsQuery['layouts']['items'];
export type ProjectLayout = ArrayElement<ProjectLayouts>;

export const GET_LAYOUTS = graphql(`
    query getLayouts($query: LayoutQuery) {
        layouts(query: $query) {
            items {
                id
                name
                status
                thumbnailUrl
                renderingProgress
                readOnly
            }
        }
    }
`);

export default function useLayouts(projectId: string | undefined, filtering: FilterLayout, sort: string): ProjectApi {
    const sortFields = sort.split('_');
    const { data, loading, error, refetch } = useQuery(GET_LAYOUTS, {
        fetchPolicy: 'network-only',
        variables: {
            query: {
                filter: {
                    projectId,
                    status: filtering.status as LayoutStatus,
                    name: {
                        contains: filtering.name,
                    },
                    renderingProgress: {
                        anyOf:
                            filtering.generation.length > 0
                                ? ([filtering.generation] as LayoutRenderingProgress[])
                                : [],
                    },
                },
                sort:
                    sortFields.length > 0
                        ? [
                              {
                                  field: sortFields[0] as LayoutSortField,
                                  descending: sortFields[1] === StringBoolean.True,
                              },
                          ]
                        : undefined,
            },
        },
    });

    return {
        loading,
        error,
        fetchLayouts: data?.layouts?.items ?? undefined,
        refetchLayouts: async () => {
            const response = await refetch();
            return response.data.layouts.items ?? undefined;
        },
    };
}
