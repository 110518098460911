import VisibilityIcon from '@mui/icons-material/Visibility';
import { Card, CardContent, CardHeader, Checkbox, CircularProgress, Typography } from '@mui/material';
import React, { ChangeEvent, ReactElement, useState } from 'react';

import { LayoutRenderingProgress, Project } from '../../../generated/gql/graphql';
import useLayoutRenderingProgressChangedSubscription from '../../../hooks/use-layout-rendering-progress-changed';
import useLayoutThumbnailChangedSubscription from '../../../hooks/use-layout-thumbnail-changed';
import { ProjectLayout } from '../../../hooks/use-layouts';
import DuplicateLayoutModal from '../../../modals/duplicate-layout-modal';
import LayoutActionsModal from '../../../modals/layout-actions-modal';
import PreviewRenderingPicture from '../../../modals/preview-picture-modal';
import { classNames } from '../../../utils/classes';
import { NonPartial } from '../../../utils/types';
import LayoutDropdownSelector from '../../selectors/layout-dropdown-selector';

export type LayoutItemProps = {
    layout: NonPartial<
        Pick<ProjectLayout, 'id' | 'name' | 'status' | 'renderingProgress' | 'thumbnailUrl' | 'readOnly'>
    >;
    project: NonPartial<Pick<Project, 'id' | 'workspaceId' | 'readOnly'>> | undefined;

    /**
     * Called when the layouts have changed
     */
    layoutsChange: () => void | Promise<void>;

    selected: boolean;
    select: () => void;
    unselect: () => void;
};

const StatusColor = {
    Failed: {
        color: '#e74c3c',
        name: 'Failed',
    },
    Generated: {
        color: '#07bc0c',
        name: 'Completed',
    },
    InProgress: {
        color: '#f1c40f',
        name: 'In Progress',
    },
    NotStarted: {
        color: '#757575',
        name: 'Not Started',
    },
    PreviewAvailable: {
        color: '#07bc0c',
        name: 'Preview Available',
    },
    PreviewFailed: {
        color: '#e74c3c',
        name: 'Preview Failed',
    },
    PreviewInProgress: {
        color: '#f1c40f',
        name: 'Preview In Progress',
    },
};

export default function LayoutItem({
    layout: initialLayout,
    project,
    layoutsChange,
    selected,
    select,
    unselect,
}: LayoutItemProps): ReactElement {
    const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [isPreviewImage, setIsPreviewImage] = useState<boolean>(false);

    const { layout: updatedLayout } = useLayoutRenderingProgressChangedSubscription(initialLayout.id);
    const { data: thumbnailChangedData } = useLayoutThumbnailChangedSubscription(initialLayout.id);

    const layout = { ...initialLayout, ...updatedLayout, ...thumbnailChangedData?.layoutThumbnailChanged };

    const handleSelectLayout = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.checked) {
            select();
        } else {
            unselect();
        }
    };

    const handleClickLayout = (): void => {
        setIsActionsModalOpen(!isActionsModalOpen);
    };

    const handleToggleActionsModal = (): void => {
        setIsActionsModalOpen(!isActionsModalOpen);
    };

    const handleDuplicateModalClosed = (): void => {
        setIsDuplicateModalOpen(false);
    };

    return (
        <Card
            id={layout.id}
            style={{ border: '1px solid #E8E8E8', boxShadow: 'none' }}
            className='border-1 on-hover relative flex w-[calc(25%_-_15px)] flex-col rounded-[15px]'
        >
            <CardHeader
                avatar={
                    <>
                        <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                            id={layout.id}
                            onChange={handleSelectLayout}
                            value={layout.id}
                            checked={selected}
                            // disabled={!layout.renderingBackground}
                        />
                        {/* <Checkbox 
                            icon={<FavoriteBorder />} 
                            checkedIcon={<Favorite />} 
                        /> */}
                    </>
                }
                action={<LayoutDropdownSelector project={project} layout={layout} layoutsChange={layoutsChange} />}
            />

            <CardContent className='flex flex-grow flex-col'>
                <div className='relative mx-[20px] flex flex-grow cursor-pointer items-center justify-center'>
                    {(layout.renderingProgress === LayoutRenderingProgress.Generated ||
                        layout.renderingProgress === LayoutRenderingProgress.PreviewAvailable) && (
                        <div
                            onClick={() => setIsPreviewImage(true)}
                            style={{ background: '#727070b3' }}
                            className='hovered absolute bottom-0 left-0 z-10 hidden h-[30px] w-full cursor-pointer text-center text-white'
                        >
                            <VisibilityIcon />
                        </div>
                    )}
                    {layout.thumbnailUrl ? (
                        <div onClick={handleClickLayout}>
                            <img
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                src={layout.thumbnailUrl}
                                alt={layout.thumbnailUrl}
                            />
                        </div>
                    ) : (
                        <CircularProgress />
                    )}
                </div>
                <div className='p-[20px] leading-[17px] last:pb-[10px]'>
                    <Typography className='mb-[10px] max-h-[40px] overflow-hidden break-keep text-[14px]'>
                        {layout.name}
                    </Typography>
                    {layout.renderingProgress && (
                        <Typography className='mb-[10px] break-keep text-[14px] capitalize'>
                            <span
                                style={{ background: `${StatusColor[layout.renderingProgress].color}` }}
                                className={classNames(
                                    'color-[#000000] border-1 mb-[-1px] mr-[5px] inline-block',
                                    'h-[10px] w-[10px] rounded-full border-slate-600 bg-slate-500',
                                )}
                            />
                            {StatusColor[layout.renderingProgress].name}
                        </Typography>
                    )}
                </div>
            </CardContent>
            <LayoutActionsModal
                headline=''
                open={isActionsModalOpen}
                layout={layout}
                onClose={handleToggleActionsModal}
                onDuplicate={() => setIsDuplicateModalOpen(true)}
            />
            <DuplicateLayoutModal
                project={project}
                layout={layout}
                open={isDuplicateModalOpen}
                layoutsChange={layoutsChange}
                onClose={handleDuplicateModalClosed}
            />
            {isPreviewImage && (
                <PreviewRenderingPicture
                    layout={layout}
                    onClose={() => setIsPreviewImage(false)}
                />
            )}
        </Card>
    );
}
