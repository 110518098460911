import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';

import { AppConfig, AppConfigContext } from './app-config-context';

export function AppConfigProvider({ children }: PropsWithChildren): ReactElement {
    const [value, setValue] = useState<AppConfigContext>({
        loading: true,
        config: null,
    });

    useEffect(() => {
        async function fetchConfig(): Promise<void> {
            const configUrl = `${process.env.PUBLIC_URL}/config.json`;
            const response = await fetch(configUrl);
            let config: AppConfig;
            const responseContentType = response.headers?.get('Content-Type');
            if (response.status !== 200 || responseContentType !== 'application/json') {
                console.error(`Failed to load app config from config.json, fallback to local config`);
                config = {
                    backend: {
                        apiUrl: process.env.REACT_APP_BACKEND_API_URL ?? 'http://localhost:4000/api',
                        subscriptionsUrl: process.env.REACT_APP_BACKEND_SUBSCRIPTIONS_URL ?? 'ws://localhost:4000/api',
                    },
                    keycloak: {
                        realm: process.env.REACT_APP_KEYCLOAK_REALM ?? 'metaphore',
                        url: process.env.REACT_APP_KEYCLOAK_URL ?? 'https://id.metaphore.com/auth/',
                        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID ?? 'metacomp-ui-dev',
                    },
                    markerIo: {
                        project: process.env.REACT_APP_MARKER_IO_PROJECT ?? '',
                    },
                };
            } else {
                config = await response.json();
            }
            // eslint-disable-next-line no-console
            console.log(`Loaded app config:\n${JSON.stringify(config, undefined, 2)}`);
            setValue({ loading: false, config });
        }
        // eslint-disable-next-line no-void
        void fetchConfig();
    }, []);

    return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
}
