import React, { ReactElement } from 'react';
import { Carousel } from 'react-responsive-carousel';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slide1 from '../assets/slide1.gif';
import slide2 from '../assets/slide2.gif';
import slide3 from '../assets/slide3.png';
import slide4 from '../assets/slide4.gif';
import Modal from '../components/modal/modal';

type Props = {
    headline: string;
    onClose(): void;
};

export default function ReleaseNotesModal(props: Props): ReactElement {
    const { headline, onClose } = props;

    return (
        <Modal open headline={headline} onClose={onClose} className='h-fit max-w-xl'>
            <div className='mb-[15px] text-[14px]'>
                <Carousel autoPlay={false} infiniteLoop showThumbs={false}>
                    <div className='mx-[50px] min-h-full rounded-xl bg-[#F6F6F6] px-[55px] py-[35px]'>
                        <div className='text-left'>
                            <h2 className={`mb-[10px] font-['Brown_Std'] font-sans text-[18px] font-bold`}>
                                01 | Quick Preview Button
                            </h2>
                            <p
                                className={`mb-[20px] font-['Brown_Std'] font-sans text-[16px] font-light text-[#1F2937]`}
                            >
                                When hovering over rendered layouts, you can click on the eye-icon to show a quick
                                preview of the result without downloading the image.
                            </p>
                        </div>
                        <img className='max-h-[300px] !w-auto' src={slide1} alt='' />
                    </div>
                    <div className='mx-[50px] min-h-full rounded-xl bg-[#F6F6F6] px-[55px] py-[35px]'>
                        <div className='text-left'>
                            <h2 className={`mb-[10px] font-['Brown_Std'] font-sans text-[18px] font-bold`}>
                                02 | Cost-Free Previews
                            </h2>
                            <p
                                className={`mb-[20px] font-['Brown_Std'] font-sans text-[16px] font-light text-[#1F2937]`}
                            >
                                From now on all preview renderings will be free of charge and contain a watermark
                                instead to make quality control more flexible. Also they are double the size at
                                1000x1000px!
                            </p>
                        </div>
                        <img className='max-h-[300px]' src={slide4} alt='' />
                    </div>
                    <div className='mx-[50px] min-h-full rounded-xl bg-[#F6F6F6] px-[55px] py-[35px]'>
                        <div className='text-left'>
                            <h2 className={`mb-[10px] font-['Brown_Std'] font-sans text-[18px] font-bold`}>
                                03 | More Filter Options
                            </h2>
                            <p
                                className={`mb-[20px] font-['Brown_Std'] font-sans text-[16px] font-light text-[#1F2937]`}
                            >
                                Easily find the product of your choice, with new filter options: Sub Brand and Language.
                            </p>
                        </div>
                        <img className='max-h-[300px]' src={slide3} alt='' />
                    </div>
                    <div className='mx-[50px] min-h-full rounded-xl bg-[#F6F6F6] px-[55px] py-[35px]'>
                        <div className='text-left'>
                            <h2 className={`mb-[10px] font-['Brown_Std'] font-sans text-[18px] font-bold`}>
                                04 | Variable Grid Overlay
                            </h2>
                            <p
                                className={`mb-[20px] font-['Brown_Std'] font-sans text-[16px] font-light text-[#1F2937]`}
                            >
                                It is now easier than ever to create appealing compositions with the variable grid
                                overlay.
                            </p>
                        </div>
                        <img className='max-h-[300px]' src={slide2} alt='' />
                    </div>
                </Carousel>
            </div>
        </Modal>
    );
}
