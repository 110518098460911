import { useQuery } from '@apollo/client';
import React, { ReactElement } from 'react';

import MaintenancePage from '../components/pages/maintenance';
import { graphql } from '../generated/gql/gql';
import { NextMaintenanceQuery } from '../generated/gql/graphql';

const GET_MAINTENANCE = graphql(`
    query NextMaintenance {
        nextMaintenance {
            id
            from
            to
        }
    }
`);

export default function MaintenanceProvider({ children }: { children: ReactElement }): ReactElement {
    const { data: maintenance, loading } = useQuery<NextMaintenanceQuery>(GET_MAINTENANCE, {
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return <></>;
    }

    if (maintenance?.nextMaintenance) {
        const dateStart = new Date(maintenance.nextMaintenance.from);
        const dateNow = new Date();

        if (dateStart <= dateNow) {
            return <MaintenancePage maintenance={maintenance.nextMaintenance} />;
        }
    }

    return children;
}
