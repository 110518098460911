import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';

import { graphql } from '../generated/gql';
import { ProjectLayout } from '../hooks/use-layouts';
import { NonPartial } from '../utils/types';

const GET_LAYOUT_JOBS = graphql(`
    query getLayoutJobs($layoutId: ID!) {
        layout(id: $layoutId) {
            previewRenderingJob {
                result {
                    renderingUrl
                }
            }
            latestRenderingJob {
                result {
                    renderingUrl
                }
            }
        }
    }
`);

export interface PreviewRenderingJobProps {
    layout: NonPartial<Pick<ProjectLayout, 'id' | 'name'>>;
    onClose: () => void;
}

export default function PreviewRenderingPicture(props: PreviewRenderingJobProps): React.ReactElement {
    const { onClose, layout } = props;

    const [isPreviewImagesLoaded, setIsPreviewImagesLoaded] = React.useState<boolean>(false);
    const { data, loading } = useQuery(GET_LAYOUT_JOBS, {
        variables: { layoutId: layout.id },
        fetchPolicy: 'network-only',
    });

    const preview = data?.layout?.previewRenderingJob?.result?.renderingUrl;
    const generated = data?.layout?.latestRenderingJob?.result?.renderingUrl;

    if (loading) return <p>Loading ...</p>;

    if (!preview && !generated) {
        return <></>;
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={false}
            open
            onClose={onClose}
            sx={{
                '& .MuiPaper-root': {
                    minWidth: '100%',
                    minHeight: '100%',
                    background: 'none',
                },
            }}
        >
            <DialogContent onClick={onClose} className='flex flex-wrap items-center justify-center text-center'>
                <>
                    {!isPreviewImagesLoaded && <CircularProgress />}
                    <img
                        onLoad={() => setIsPreviewImagesLoaded(true)}
                        className={`${isPreviewImagesLoaded ? 'inline-block' : 'hidden'}`}
                        src={generated || preview}
                        alt={layout.name || ''}
                    />
                </>
            </DialogContent>
        </Dialog>
    );
}
